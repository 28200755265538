import React , {Fragment } from 'react';  
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne"; 
import ContactForm from '../components/ContactForm'
import MetaDescription from "../data/Meta/description.json";
const ContactPage = () => {
    
    return(

        <Fragment>
            <MetaTags>
              <title>Island Digital | Contact</title>
              <meta
                name="description"
                content={MetaDescription.description}
              />
            </MetaTags>

            <LayoutOne>
              <ContactForm /> 
            </LayoutOne>
        </Fragment>

      );
  }

     

export default ContactPage;   