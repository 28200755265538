import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutThree from "../layouts/LayoutThree"; 
import Slider from '../components/Slider/SliderThree'
import WhyChooseUs from '../components/WhyChooseUs' 
import ContactForm from '../components/ContactForm';   
import MetaDescription from "../data/Meta/description.json";
 
const Home = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Island Digital | Home</title>
        <meta
          name="description"
          content={MetaDescription.description}
        />
      </MetaTags>
      <LayoutThree>
    
        <Slider />  
        <WhyChooseUs /> 
        <ContactForm /> 
 
      </LayoutThree>
    </Fragment>
  );
};

export default Home;
