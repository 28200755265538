import React from 'react'; 
import { Link } from "react-scroll";

const Navbar  = () => {
    return (
        <ul>
            <li> 
                <Link activeClass="active" to="top" spy={true} smooth={true} offset={-70} duration={500} >
                    Home 
                </Link>
            </li>
            <li> 
                <Link activeClass="active" to="quote" spy={true} smooth={true} offset={0} duration={500} >
                    Services
                </Link>
            </li>
            <li> 
                <Link activeClass="active" to="contact" spy={true} smooth={true} offset={0} duration={500} >
                    Contact 
                </Link>
            </li>
        </ul>

    );
}
export default Navbar;