import React from 'react';
 
const ContactInfo = () => {

    return (

      <div className="row">
        
        <div className="bottom_contact col-sm-4 col-xs-12"><a href="https://www.google.com/maps/@48.2975384,-122.6499561,14z"><i className="icon_pin_alt"></i></a>
            <p>Island Digital </p>
            <a href="https://www.google.com/maps/@48.2975384,-122.6499561,14z"><h4>Oak Harbor, Washington</h4></a>
        </div>
        
        <div className="bottom_contact col-sm-4 col-xs-12"><a href="tel:4256775273"><i className="icon_phone"></i></a>
            <p>Phone</p>
            <a href="tel:4256775273"><h4>(425) 677-5273</h4></a>
        </div>
        <div className="bottom_contact col-sm-4 col-xs-12"><a href="mailto:info@islanddigital.io"><i className="icon_mail_alt"></i></a>
            <p>Email</p>
            <h4><a href="mailto:info@islanddigital.io">info@islanddigital.io</a></h4>
        </div>
      </div>

    );
}

export default ContactInfo;