import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import LandingPageOne from "./pages/LandingPageOne";
import ContactPage from "./pages/Contact";
import WhyChoosePage from "./pages/WhyChoose";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            component={LandingPageOne}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/contact"}`}
            component={ContactPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/service"}`}
            component={WhyChoosePage}
          />
          <Route exact component={NotFound} />

        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default App;
