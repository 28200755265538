import React, { useState, useEffect } from "react";
import { animateScroll } from "react-scroll";
import Widget from "../UI/Widget";
 
const Footer = () => {
 
    const [scroll, setScroll] = useState(0);
    const [top, setTop] = useState(0);
  
    useEffect(() => {
      setTop(100);
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      animateScroll.scrollToTop();
    };
  
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    return(
    <footer className="footer-section">
        <div className="container">
            <div className="row"> 
                {/*  Start:About  */}
                <div className="col-lg-3 col-sm-12 margin_bottom_special">
					<Widget title="About">
                        <p>I'm an IT professional with thirty eight years of experience working for small and large tech companies as a software developer, dev manager, and architect.  I get great satisfaction from helping organizations achieve their goals through technology.  I founded Island Digital with the aim of providing personalized IT services to local businesses on Whidbey Island and the greater Island County area.  I look forward to working with you on your next IT project.</p>
                    </Widget>
                </div> 
                {/*  End:About  */}

                {  /*Start: Profile Image  */} 
                <div className="col-md-6 col-sm-12"> 

                    { /*  Profile Image  */}

                    <img height="170px" src={process.env.PUBLIC_URL + '/assets/images/larry_daponte_profile_bw.jpg'} alt="" /><br />
                    <p className="sm_special">Larry da Ponte, Founder and CEO</p>

                </div> 
                {/*End: Profile Image  */}

                

                {/*  Start:Subfooter  */}
                <div className="subfooter"> 
                    <div className="copyright_text"> &copy; {new Date().getFullYear()} Island Digital LLC
                    </div> 

                    {/* SOCIAL ICON */} 
                    <div className="footer_social"> 
                        <ul className="footer_social_icons">
                        <li> <a href="https://www.linkedin.com/company/island-digital-llc/about/"><i className="fa fa-linkedin"></i></a> </li>
                        </ul>
                    </div>

                    <button className={`scrollup ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()} > 
                        <span className="arrow_triangle-up"></span>
                    </button> 
                </div> 
                {/* End:Subfooter  */}

            </div>
        </div> 

    </footer> 

        )
 
}

 
export default Footer;