import React, { Fragment } from "react"; 
import MetaTags from "react-meta-tags";
import LayoutOne from "../layouts/LayoutOne"; 
import WhyChoose from '../components/WhyChooseUs';
import MetaDescription from "../data/Meta/description.json";

const servicePage = () => {
	return (
	  <Fragment>
		<MetaTags>
		  <title>Island Digital | Services</title>
		  <meta
			name="description"
			content={MetaDescription.description}
		  />
		</MetaTags>
		<LayoutOne>
            <WhyChoose />
		</LayoutOne>
	  </Fragment>
	);
  };
  
  export default servicePage;



